<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <app-button
              text="Görev Ekle"
              block
              @click="$emit('close-left-sidebar'), $showAppSidebar('Görev Ekle', TodoForm)"
            />
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title"
                class="cursor-pointer"
                :active="false"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <hr>

            <!-- Tags -->
            <div class="mt-1 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Etiketler
              </h6>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.title"
                class="cursor-pointer"
                :active="false"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TodoForm from './TodoForm.vue'

export default {
  components: { VuePerfectScrollbar },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      taskFilters: [
        { title: 'Görevlerim', icon: 'MailIcon' },
        { title: 'Önemli', icon: 'StarIcon' },
        { title: 'Tamamlandı', icon: 'CheckIcon' },
        { title: 'Silindi', icon: 'TrashIcon' },
      ],
      TodoForm,
    }
  },
}
</script>

<style></style>
